import React, { useContext, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import ProtectedRoute from '../components/ProtectedRoute';
import SEO from '../components/SEO';
import WithHeroLayout from '../layouts/WithHeroLayout';
import firebase from 'gatsby-plugin-firebase';
import { device } from '../constants';
import Section from '../components/formatting/Section';
import SectionDescription from '../components/formatting/SectionDescription';
import MembersAnnouncementComponent from '../components/dashboard/members-announcements';
import MembersEventsComponent from '../components/dashboard/members-events';
import DashboardSidebar from '../components/dashboard/DashboardSidebar';
import SiteConfigContext from '../context/SiteConfigContext';

const Container = styled.section`
  display: flex;
  flex-flow: row wrap;

  @media screen and ${device.laptop} {
  }
`;

const LeftSidebarWrapper = styled.div`
  margin-right: 0;
  margin-bottom: ${props => props.theme.paddings.pd * 2}px;
  flex: 1 0 400px;

  @media screen and ${device.laptop} {
    margin-right: ${props => props.theme.paddings.pd * 2}px;
  }
`;

const RightContentWrapper = styled.section`
  margin-right: auto;
  flex: 1 1 100%;

  @media screen and ${device.laptop} {
    flex: 1 0 650px;
  }
`;

const DashboardPage: React.FC = function () {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    async function fetchUserData() {
      const { data } = await firebase
        .functions()
        .httpsCallable('getUserData')();
      setUserInfo(data);
    }

    fetchUserData();
  }, []);

  const { first_name, last_name } = userInfo;
  const theme = useTheme();
  const siteCfg = useContext(SiteConfigContext);

  const pageTitle = `hello, ${
    userInfo && first_name && last_name ? `${first_name} ${last_name}` : 'there'
  }!`;

  return (
    <ProtectedRoute>
      <WithHeroLayout
        pageTitle={pageTitle}
        bgImgSrc="/images/design/hero/coral.png"
        color={theme.colors.purple}
      >
        <SEO title="Dashboard" />
        <Section>
          <SectionDescription>
            Welcome back to your SDEA Dashboard.
          </SectionDescription>
          <Container>
            <LeftSidebarWrapper>
              <DashboardSidebar data={siteCfg.dashboard_items ?? []} />
            </LeftSidebarWrapper>
            <RightContentWrapper>
              <MembersAnnouncementComponent />
            </RightContentWrapper>
          </Container>
        </Section>
        <MembersEventsComponent />
      </WithHeroLayout>
    </ProtectedRoute>
  );
};

export default DashboardPage;
