import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import useFirebaseAuth from '../hooks/useFirebaseAuth';

const ProtectedRoute: React.FC = function (props) {
  const { children } = props;
  const { loading, currentUser } = useFirebaseAuth();

  useEffect(() => {
    if (!loading && currentUser === null) {
      navigate('/login');
    }
  }, [loading, currentUser]);

  if (currentUser === null) {
    return null;
  }

  return children;
};

export default ProtectedRoute;
