import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import moment from 'moment';
import DateAndAuthor from '../formatting/DateAndAuthor';
import LinkButton from '../form-elements/LinkButton';

const Container = styled.section`
  & a {
    color: #000;
  }
`;
const PostList = styled.section``;

const PostItem = styled.article`
  display: flex;
  flex-flow: row wrap-reverse;

  margin-bottom: 48px;
  padding-bottom: ${props => props.theme.paddings.pd}px;
  border-bottom: 1px solid ${props => props.theme.colors.purple};
`;
const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
`;

const ContentContainer = styled.div`
  flex: 1 1 50%;
  margin-right: ${props => props.theme.paddings.pd * 2}px;
`;
const LinkButtonWrapper = styled.div`
  display: inline-block;
`;
const FeaturedImageWrapper = styled.div`
  flex: 1 1 200px;
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
`;

const Excerpt = styled.p`
  font-size: 18px;
  font-family: 'Open Sans';
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
`;

interface FeaturedImgProps {
  bgImg: string;
}
const FeaturedImg = styled.div<FeaturedImgProps>`
  padding-bottom: 100%;
  background-image: url('${props =>
    props.bgImg ? props.bgImg : '/images/design/hero/resources.png'}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: ${props => props.theme.paddings.halfpd}px;
`;

const MembersAnnouncementComponent: React.FC = function () {
  const announcementsResult = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "news" }
          childMdx: {
            frontmatter: { category: { eq: "Members' Announcements" } }
          }
        }
        sort: { fields: childMdx___frontmatter___pub_date, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            childMdx {
              frontmatter {
                title
                pub_date
                link
                excerpt
                dc_creator
                featured_image
              }
            }
          }
        }
      }
    }
  `);

  const { edges } = announcementsResult?.allFile;
  const entries = edges
    ?.map((edge: any) => edge.node.childMdx.frontmatter)
    .map((frontmatter: any) => {
      return {
        ...frontmatter,
        link: `/news${frontmatter.link}`,
        dayOfWeek: moment(frontmatter.pub_date).format('dddd'),
        pub_date: moment(frontmatter.pub_date).format('MMMM DD, YYYY'),
      };
    });

  return (
    <Container>
      <h1>Members' Announcements</h1>

      <PostList>
        {entries?.map((entry: any, index: number) => (
          <PostItem key={index}>
            <ContentContainer>
              <Title>
                <Link to={entry.link}>{entry.title}</Link>
              </Title>
              <Excerpt>{entry.excerpt}</Excerpt>
              <LinkButtonWrapper>
                <LinkButton
                  buttonType="outline"
                  link={entry.link}
                  buttonText="Read More"
                  background={'#FFF'}
                />
              </LinkButtonWrapper>
            </ContentContainer>
            <FeaturedImageWrapper>
              <FeaturedImg bgImg={entry.featured_image} />
              <DateAndAuthor>
                {entry.pub_date}
                <br />
                {entry.dayOfWeek}
                <br />
                by {entry.dc_creator}
              </DateAndAuthor>
            </FeaturedImageWrapper>
          </PostItem>
        ))}
      </PostList>
    </Container>
  );
};

export default MembersAnnouncementComponent;
